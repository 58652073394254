<template>
  <b-modal
    id="modal-modify-add-baggage"
    title="Thêm hành lý"
    title-class="text-airline font-medium-3 fw-700"
    body-class="px-75"
    centered
    size="lg"
    no-close-on-backdrop
    no-enforce-focus
    @show="handleShowModal"
    @hide="handleHideModal"
  >
    <template #modal-footer="{ close }">
      <div class="d-flex-center w-100">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill mr-2"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="info"
          class="px-2 d-flex-center"
          pill
          @click="handleConfirmChange"
        >
          <!-- :disabled="isEmpty(getPaxListsToAdd)" -->
          <!-- <span class="align-middle">{{ $t('reservation.continue') }}</span> -->
          <div class="d-flex-center ml-25">
            <BSpinner
              v-if="loadingNextButton"
              small
              class="mx-2"
            />
            <span v-else>Tiếp tục</span>
          </div>
        </b-button>
      </div>
    </template>

    <b-overlay
      :show="isShow"
      rounded="sm"
      no-fade
      variant="white"
      spinner-variant="info"
      :opacity="0.9"
      no-center
    >
      <template #overlay>
        <div class="text-center py-2 mt-4">
          <p
            id="cancel-label"
            class="font-weight-bolder text-airline"
          >
            Đang tải dữ liệu ...
          </p>
          <div class="text-center">
            <b-spinner variant="info" />
          </div>
        </div>
      </template>

      <p class="fw-700 text-medium-1">
        Chọn gói hành lý mua thêm:
      </p>

      <BAlert
        v-if="['QH', 'JQ'].includes(bookingSource)"
        show
        variant="warning"
        class="mx-25 px-1 py-50 fw-700 mb-75"
      >
        <p class="text-danger">
          - {{ bookingSource }}: Tối đa mua thêm {{ fnAncillary?.BAGGAGE?.maximumWeight }}KG/ 1 khách/ 1 hành trình.
        </p>
        <p
          v-if="isShowWarningQH"
          class="text-danger"
        >
          - Vé đã mua hành lý 1 chặng, chặng còn lại phải mua gói hành lý khác gói đã mua trước đó, nếu cần mua gói tương tự vui lòng chat Booker.
        </p>
      </BAlert>

      <b-card
        v-for="(tripData, indexTripData) of arrDataHandle"
        :key="indexTripData"
        header-bg-variant="light-warning"
        header-class="py-50 px-1"
        body-class="pb-0 pt-50 px-75"
        class="border-warning shadow-lg"
      >
        <template #header>
          <div class="text-airline fw-700">
            <span class="font-small-4 mr-50">Hành trình: </span>
            {{ ['TR', 'JQ'].includes(bookingSource) ? tripData.flights : `${tripData.trip[0].departure.iataCode}-${tripData.trip[tripData.trip.length - 1].arrival.iataCode}` }}
            ({{ convertISODateTime(tripData.trip[0].departure.at, tripData.trip[0].departure.timeZone).date }})
          </div>
        </template>

        <CardNamePax
          :arr-pax="tripData.arrPax"
          :name-type="'hành lý'"
        />
        <b-card
          header-class="px-0 pt-50 pb-50 pb-md-0"
          body-class="px-0 py-50 px-md-1 px-lg-2"
          class="mb-50"
        >
          <template #header>
            <span class="text-body fw-700">Chọn gói hành lý: </span>
          </template>

          <template v-if="!isEmpty(getBaggageByTrip(tripData))">
            <b-row
              v-for="(bagItem, indexBag) of getBaggageByTrip(tripData)"
              :key="indexBag"
              class="mb-1 mb-md-75 justify-content-center"
              no-gutters
            >
              <b-col
                :cols="fnAncillary.BAGGAGE.increase || fnAncillary.BAGGAGE.decrease ? '12' : '10'"
                md="8"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  :id="`baggageItem_${indexTripData}_${indexBag}`"
                  :checked="isChecked(tripData.newAddItems, bagItem)"
                  inline
                  :disabled="checkDisableItem(tripData, bagItem)"
                  @change="chooseItem(tripData.newAddItems, bagItem)"
                >
                  <span :class="`font-weight-bolder ${['lg', 'xl'].includes(appBreakPoint) ? 'font-medium-1' : 'font-small-3'}`"> {{ resolveSsrBagsName(bagItem) }}</span>
                  <span :class="`text-warning font-italic fw-700 font-medium-1 ${['lg', 'xl'].includes(appBreakPoint) ? 'font-medium-1' : 'font-small-3'}`">
                    {{
                      !isEmpty(bagItem) && bagItem.fares.find(f => ['ALL', 'ADULT', ''].includes(f.paxType))
                        ? formatCurrency(bagItem.fares.find(f => ['ALL', 'ADULT', ''].includes(f.paxType)).total)
                        : '-'
                    }}
                  </span>
                </b-form-checkbox>
              </b-col>

              <b-col
                :cols="fnAncillary.BAGGAGE.increase || fnAncillary.BAGGAGE.decrease ? '12' : '2'"
                md="4"
              >
                <div
                  v-if="isChecked(tripData.newAddItems, bagItem)"
                  class="d-flex align-items-center justify-content-end"
                >
                  <div class="mr-50 d-none d-lg-inline">
                    Số lượng:
                  </div>
                  <div class="d-flex">
                    <b-button
                      v-if="fnAncillary.BAGGAGE.decrease"
                      variant="flat-danger"
                      class="px-75 py-0 fw-700"
                      pill
                      @click="decreaseQuantity(tripData.newAddItems, bagItem)"
                    >
                      <feather-icon
                        icon="MinusIcon"
                        size="14"
                      />
                    </b-button>
                    <b-form-input
                      v-remove-non-numeric-chars
                      :value="getValueQuantityBagItem(tripData.newAddItems, bagItem)"
                      style="width: 40px;"
                      :maxlength="2"
                      :disabled="true"
                      size="sm"
                      class="mx-50 px-50 fw-700 text-center font-medium-3 rounded"
                    />
                    <b-button
                      v-if="fnAncillary.BAGGAGE.increase"
                      variant="flat-info"
                      class="px-75 py-0 fw-700"
                      :disabled="!fnAncillary.BAGGAGE.increase"
                      pill
                      @click="increaseQuantity(tripData, bagItem)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="14"
                      />
                    </b-button>
                  </div>
                </div>

                <div
                  v-else
                  class="d-flex align-items-center justify-content-end"
                >
                  <div class="mr-50 text-secondary d-none d-lg-inline">
                    Số lượng:
                  </div>
                  <div class="d-flex">
                    <b-button
                      v-if="fnAncillary.BAGGAGE.decrease"
                      variant="flat-secondary"
                      class="px-75 py-0"
                      disabled
                      pill
                    >
                      <feather-icon
                        icon="MinusIcon"
                        size="14"
                      />
                    </b-button>
                    <b-form-input
                      :value="''"
                      style="width: 40px;"
                      size="sm"
                      :disabled="true"
                      class="mx-50 px-50 text-secondary text-center rounded"
                    />
                    <b-button
                      v-if="fnAncillary.BAGGAGE.increase"
                      variant="flat-secondary"
                      class="px-75 py-0"
                      disabled
                      pill
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="14"
                      />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </template>

          <template v-else-if="isShow">
            <b-spinner
              class="m-1"
              variant="info"
            />
          </template>
          <template v-else>
            <div class="text-danger my-50 font-weight-bolder">
              Không có gói hành lý khả dụng trên hành trình hiện tại!
            </div>
          </template>
        </b-card>
      </b-card>

      <ModalConfirmAddBaggage :arr-data-to-confirm="arrDataToConfirm" />
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BOverlay,
  BSpinner,
  BCard,
  BRow,
  BCol,
  BAlert,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
import {
  computed,
  ref, watchEffect,
} from '@vue/composition-api'
import {
  isEmpty, sortBy, isEqual, cloneDeep,
} from 'lodash-es'

import { resolveSsrBagsName } from '@/constants/selectOptions'

import { convertISODateTime, formatCurrency } from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BAlert,
    BFormCheckbox,
    BFormInput,
    CardNamePax: () => import('../components/CardNamePax.vue'),

    ModalConfirmAddBaggage: () => import('./modal-third-confirm.vue'),
  },
  props: {
    dataListToSecond: {
      type: [Array, null],
      default: () => null,
    },
  },
  setup(props) {
    const { toastError, toastWarning } = useToast()
    const {
      getBookingData,
      fetchListAncillary,
    } = useReservationHandle()

    const {
      fnAncillary,
      bookingSource,
      delay,
      sortBaggagesVJ,
      sortBaggagesAirlines,
    } = useAncillaryServicesHandle()

    const arrDataHandle = ref(null)
    const resBaggage = ref(null)
    const arrDataToConfirm = ref(null)

    const getBaggageByTrip = tripData => {
      if (resBaggage.value) {
        let result = []

        if (['VJ'].includes(bookingSource.value)) {
          result = resBaggage.value.filter(bag => (bag.flights === tripData.flights) && isEqual(bag.segmentIds[0], tripData.trip[0].segmentId))
        } else if (['F1'].includes(bookingSource.value)) {
          result = resBaggage.value.filter(bag => (bag.flights === tripData.flights))
        } else if (['VN1A', 'VN1A_MT'].includes(bookingSource.value)) {
          result = resBaggage.value.filter(bag => /* (bag.flights === tripData.flights) && */ tripData.trip.some(s => s.devItineraryIndex === bag.itineraryId))
        } else if (['TR'].includes(bookingSource.value)) {
          result = resBaggage.value.filter(bag => (bag.segmentIds?.length === tripData.segmentIds?.length) && bag.segmentIds.every(bagSegmentId => tripData.segmentIds.some(it => it === bagSegmentId)))
        } else if (['JQ'].includes(bookingSource.value)) {
          result = resBaggage.value.filter(bag => (bag.segmentIds?.length === tripData.trip?.length) && bag.segmentIds.every(bagSegmentId => tripData.trip.some(segment => segment.segmentId === bagSegmentId)))
        } else {
          result = resBaggage.value.filter(bag => (bag.flights === tripData.flights) && isEqual(sortBy(bag.segmentIds), sortBy(tripData.trip.map(sg => String(sg.segmentId)))))
        }

        if (['VJ'].includes(bookingSource.value)) {
          result.sort(sortBaggagesVJ)
        } else {
          result.sort(sortBaggagesAirlines)
        }

        return result
      }
      return []
    }

    // NOTE: ==== Fetch and check overloading
    const isShow = ref(true)
    const errorBagData = ref(null)
    const loadingNextButton = ref(false)

    function setTimeoutWithoutId(callback, delay) {
      return setTimeout(callback, delay)
    }

    watchEffect(() => {
      if (isShow.value && !isEmpty(resBaggage.value) && !isEmpty(arrDataHandle.value)) {
        setTimeoutWithoutId(() => { isShow.value = false }, 700)
      }
    })

    function handleFetchListAncillary(data) {
      errorBagData.value = null
      fetchListAncillary(data)
        .then(res => {
          if (isEmpty(res.ssrBags)) {
            toastError('Không có gói hành lý khả dụng trên hành trình hiện tại!')
            errorBagData.value = 'Không có gói hành lý khả dụng trên hành trình hiện tại!'
          } else {
            resBaggage.value = res.ssrBags.map(b => ({ ...b, quantity: 1 }))
          }
        })
        .catch(() => {
          errorBagData.value = true
        })
        .finally(() => {
          isShow.value = false
        })
    }

    // ===========================
    function handleShowModal() {
      errorBagData.value = null
      arrDataHandle.value = cloneDeep(props.dataListToSecond)
      handleFetchListAncillary(getBookingData.value)
    }

    function handleHideModal() {
      resBaggage.value = null
      isShow.value = true
      arrDataHandle.value = false
    }

    // ======================= SP
    const checkIsUpdate = tripData => !isEmpty(tripData.addedAncillaryTrip) && (tripData.arrPax.length === 1) && !isEmpty(tripData.arrPax[0].addonsOfPax)
    const extractKg = values => values.map(it => Number(it.match(/\d+/))).filter(Boolean)
    const getTotalAddedValue = (name, tripData) => {
      if (['weight'].includes(name)) {
        const listStringBagName = tripData.arrPax[0].addonsOfPax.map(it => it.serviceSubCode) // tên gói đã thêm
        return extractKg(listStringBagName).reduce((t, a) => t + a, 0)
      }

      if (['amount'].includes(name)) { // tổng số lượng gói (VN1A: chỉ bán gói 23kg chưa check case nhiều gói khác nhau)
        const totalAmount = tripData.arrPax[0].addonsOfPax.reduce((total, it) => total + it.numberOfItems, 0)
        return totalAmount ?? 0
      }

      // NOTE: chưa dùng đến, 26/12
      return tripData.arrPax[0].addonsOfPax.reduce((t, a) => t + a, 0) // amount
    }

    // ======================= Check Baggage Item
    function isChecked(arr, bag) {
      return arr.some(i => (i.flights === bag.flights)
      && (['QH'].includes(bookingSource.value)
        ? (`${i.details[0].weight}${i.details[0].unit}` === `${bag.details[0].weight}${bag.details[0].unit}`)
        : (i.code === bag.code))
      && (i.segmentIds[0] === bag.segmentIds[0]))
    }

    function setArray(array, item) {
      const index = array
        ? array.findIndex(el => (el.flights === item.flights)
          && (['QH'].includes(bookingSource.value)
            ? (`${el.details[0].weight}${el.details[0].unit}` === `${item.details[0].weight}${item.details[0].unit}`)
            : (el.code === item.code))
          && (el.segmentIds[0] === item.segmentIds[0]))
        : -1

      if (index >= 0) {
        if (fnAncillary.value.BAGGAGE.increase) {
          item.quantity = 1
        }

        array.splice(index, 1)
      } else {
      // eslint-disable-next-line no-lonely-if
        if (['VJ'].includes(bookingSource.value) && !isEmpty(array)) {
        // VJ
        // trong array có gói 2kg và gói mới khác 2kg
        // trong array không có gói 2kg và gói mới là gói 2kg
        // trong array chỉ có gói 2kg
          const indexItemInSegment = array.findIndex(i => isEqual(sortBy(i.segmentIds), sortBy(item.segmentIds))
          && (!['Cabin Extra 2k'].includes(item.code)
            && (!((array.length === 1) && ['Cabin Extra 2k'].includes(array[0].code)))
            && !['Cabin Extra 2k'].includes(i.code)))

          if (indexItemInSegment >= 0) { // gán gói mới, không cộng thêm
            array.splice(indexItemInSegment, 1, item)
          } else {
            array.push(item)
          }
        } else {
          array.push(item)
        }
      }
      return array
    }

    function chooseItem(arr, bag) {
      arr = setArray(arr, bag)
    }

    const getValueQuantityBagItem = (arr, bagItem) => {
      const addons = arr.find(it => (it.code === bagItem.code)
      && (it.flights === bagItem.flights)
      && (it.segmentIds[0] === bagItem.segmentIds[0])
      && (['QH'].includes(bookingSource.value)
        ? (`${it.details[0].weight}${it.details[0].unit}` === `${bagItem.details[0].weight}${bagItem.details[0].unit}`)
        : true))
      return addons ? addons?.quantity || 1 : 1
    }

    function decreaseQuantity(newAddItems, bagItem) {
      const indexBagOfAddonsPax = newAddItems.findIndex(it => (it.code === bagItem.code)
      && (it.flights === bagItem.flights)
      && (JSON.stringify(it.segmentIds) === JSON.stringify(bagItem.segmentIds))
      && (['QH'].includes(bookingSource.value)
        ? (`${it.details[0].weight}${it.details[0].unit}` === `${bagItem.details[0].weight}${bagItem.details[0].unit}`)
        : true))

      if (indexBagOfAddonsPax < 0) {
        toastError('Lỗi thay đổi thông số lượng gói đã chọn, vui lòng tải lại trang và thực hiện lại!')
      } else {
        const currentQuantity = newAddItems[indexBagOfAddonsPax].quantity
        if (currentQuantity > 1) {
          newAddItems[indexBagOfAddonsPax].quantity -= 1
        }
      }
    }

    function increaseQuantity(tripData, bagItem) {
      const isUpdate = checkIsUpdate(tripData)

      const indexBagOfAddonsPax = tripData.newAddItems.findIndex(it => (it.code === bagItem.code)
      && (it.flights === bagItem.flights)
      && (JSON.stringify(it.segmentIds) === JSON.stringify(bagItem.segmentIds))
      && (['QH'].includes(bookingSource.value)
        ? (`${it.details[0].weight}${it.details[0].unit}` === `${bagItem.details[0].weight}${bagItem.details[0].unit}`)
        : true))
      // if (isUpdate) { // ĐÃ CÓ GÓI TRƯỚC ĐÓ
      // const arrAddonsOfPax = tripData.arrPax[0].addonsOfPax
      if (['QH'].includes(bookingSource.value)) {
        const totalBagAdded = isUpdate ? getTotalAddedValue('weight', tripData) : 0 // lấy tổng sl gói đã thêm
        const itemWeight = Number(bagItem?.details[0]?.weight) || 999
        const totalCheckedToAdd = isEmpty(tripData.newAddItems) ? 0 : tripData.newAddItems.reduce((total, item) => total + (Number(item.details[0].weight) * item.quantity), 0) // gói mới đã chọn

        if ((totalBagAdded + itemWeight + totalCheckedToAdd) > fnAncillary.value.BAGGAGE.maximumWeight) {
          toastError(`Tối đa được mua thêm ${fnAncillary.value.BAGGAGE.maximumWeight}KG/ 1 khách/ 1 hành trình!`)
          return
        }
        tripData.newAddItems[indexBagOfAddonsPax].quantity += 1
      }
      if (['VN1A', 'VN1A_MT'].includes(bookingSource.value)) {
        const totalAmountAdded = isUpdate ? getTotalAddedValue('amount', tripData) : 0 // lấy tổng sl(amount) gói đã thêm
        const totalCheckedToAdd = isEmpty(tripData.newAddItems) ? 0 : tripData.newAddItems.reduce((total, item) => total + item.quantity, 0) // gói mới đã chọn
        if (totalAmountAdded + totalCheckedToAdd > fnAncillary.value.BAGGAGE.maximumQuantityOnEach - 1) {
          toastError(`Tối đa được mua thêm ${fnAncillary.value.BAGGAGE.maximumQuantityOnEach} gói/ 1 khách/ 1 hành trình!`)
          return
        }
        tripData.newAddItems[indexBagOfAddonsPax].quantity += 1
      }
      if (['TH'].includes(bookingSource.value)) {
        tripData.newAddItems[indexBagOfAddonsPax].quantity += 1
      }
    }

    // ======================= checkDisable MaxQuantity
    function checkDisableItem(tripData, bagItem) {
      const isUpdate = !isEmpty(tripData.addedAncillaryTrip) && (tripData.arrPax.length === 1) && !isEmpty(tripData.arrPax[0].addonsOfPax)
      if (isUpdate) { // ĐÃ CÓ GÓI TRƯỚC ĐÓ
        const arrAddonsOfPax = tripData.arrPax[0].addonsOfPax

        if (['QH'].includes(bookingSource.value)) {
          const listStringBagName = tripData.arrPax[0].addonsOfPax.map(it => it.serviceSubCode) // tên gói đã thêm
          const totalBagAdded = extractKg(listStringBagName).reduce((t, a) => t + a, 0) // lấy tổng sl gói đã thêm
          const hasSelectItem = tripData.newAddItems.some(b => JSON.stringify(b) === JSON.stringify(bagItem)) // gói đã checked
          const itemWeight = Number(bagItem?.details[0]?.weight) || 999
          const totalCheckedToAdd = isEmpty(tripData.newAddItems) ? 0 : tripData.newAddItems.reduce((total, item) => total + (Number(item.details[0].weight) * item.quantity), 0) // gói mới đã chọn

          if (!hasSelectItem && (itemWeight + totalBagAdded + totalCheckedToAdd) > fnAncillary.value.BAGGAGE.maximumWeight) return true
          return false
        }

        if (['TR'].includes(bookingSource.value)) {
          const specialCodes = ['BG20', '1BBG']
          return arrAddonsOfPax.some(b => b.serviceCode === bagItem.code || (specialCodes.includes(b.serviceCode) && specialCodes.includes(bagItem.code)))
        }

        if (['JQ'].includes(bookingSource.value)) {
          const getWeightList = arrAddonsOfPax.filter(bagAdded => bagAdded.segmentIds[0] === tripData.trip[0].segmentId).map(it => it.serviceCode.slice(-2))
          const totalBagAdded = !isEmpty(getWeightList) ? getWeightList.reduce((acc, it) => acc + Number(it), 0) : 0
          const itemWeight = Number(bagItem?.details[0]?.weight) || 999
          const totalCheckedToAdd = isEmpty(tripData.newAddItems) ? 0 : tripData.newAddItems.reduce((total, item) => total + (Number(item.details[0].weight) * item.quantity), 0) // gói mới đã chọn
          const hasSelectItem = tripData.newAddItems.some(b => JSON.stringify(b) === JSON.stringify(bagItem)) // gói đã checked
          return !hasSelectItem && (totalBagAdded + itemWeight + totalCheckedToAdd) > fnAncillary.value.BAGGAGE.maximumWeight
        }

        if (['VJ'].includes(bookingSource.value)) {
          if (arrAddonsOfPax.length === 2) return true
          const had2kg = ['CBXS'].includes(arrAddonsOfPax[0].serviceSubCode)
          const code = ['Cabin Extra 2k'].includes(bagItem.code)
          return had2kg ? code : !code
        }

        if (['VU'].includes(bookingSource.value)) {
          return arrAddonsOfPax.some(b => b.serviceSubCode === bagItem.code)
        }
      } else { // CHƯA CÓ GÓI NÀO
        const newAddItems = tripData.newAddItems
        const hasSelectItem = newAddItems.some(b => JSON.stringify(b) === JSON.stringify(bagItem))

        if (!(isEmpty(newAddItems))) {
          // handle chỉ chọn 1 gói TH
          if (['TH'].includes(bookingSource.value)) {
            if (!newAddItems.length) return false
            return !hasSelectItem
          }
          if (['QH'].includes(bookingSource.value)) {
            const itemWeight = Number(bagItem?.details[0]?.weight) || 999
            const totalCheckedToAdd = tripData.newAddItems.reduce((total, item) => total + (Number(item.details[0].weight) * item.quantity), 0)

            if (!hasSelectItem && ((itemWeight + totalCheckedToAdd) > fnAncillary.value.BAGGAGE.maximumWeight)) return true
            return false
          }
        }
      }
      return false
    }

    async function handleConfirmChange() {
      // const checkData = arrDataHandle.value.every(item => !isEmpty(item.newAddItems))
      const checkData = arrDataHandle.value.filter(item => !isEmpty(item.newAddItems))
      if (isEmpty(checkData)) {
        toastWarning({
          title: 'Vui lòng chọn gói hành lý cho ít nhất một hành trình!',
        })
        return
      }

      // arrDataToConfirm.value = arrDataHandle.value.filter(item => !isEmpty(item.newAddItems))
      arrDataToConfirm.value = checkData
      loadingNextButton.value = true

      try {
        await delay(300)
        this.$bvModal.show('modal-modify-confirm-add-baggage')
      } catch (error) {
        console.error('Error openModalBaggageSecond::', error)
      } finally {
        await delay(200)
        loadingNextButton.value = false
      }

      // this.$bvModal.show('modal-modify-confirm-multi-add-baggage')
    }

    const isShowWarningQH = computed(() => ['QH'].includes(getBookingData.value?.source) && ['RT'].includes(getBookingData.value?.flightType))

    return {
      isShow,
      isEmpty,
      fnAncillary,
      bookingSource,
      getBaggageByTrip,
      resolveSsrBagsName,
      convertISODateTime,

      arrDataHandle,
      resBaggage,
      formatCurrency,
      handleConfirmChange,

      handleShowModal,
      handleHideModal,

      isChecked,
      chooseItem,
      loadingNextButton,

      decreaseQuantity,
      increaseQuantity,
      arrDataToConfirm,
      getValueQuantityBagItem,

      checkDisableItem,
      // checkDisableMaxQuantity,

      isShowWarningQH,
    }
  },
}
</script>
